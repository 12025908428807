/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React, { Fragment, useEffect, useRef, useState } from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"

//import 'jquery';
//import '../../static/css3-animateit.js';
import Header from "./header"
import Footer from "./footer"


const Layout = ({ children, styleClass }) => {
  const [menuOpen, setMenuOpen] = useState(false);

  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `);


  return (
    <div className={`${(menuOpen) ? 'hdr-open' : ''}`}>
      <Header menuOpen={menuOpen} setMenuOpen={setMenuOpen} siteTitle={data.site.siteMetadata.title} />

      <main className={styleClass}> {children}</main>

      <Footer />
    </div>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
