import { Link } from "gatsby"

import React from "react"

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import { faFacebookSquare, faTwitter } from '@fortawesome/free-brands-svg-icons' //fab fa-facebook-square

import Logo from "../images/svg/msm-logo.svg"
import LinkedInLogo from "../images/svg/icons/Linkedin.svg"
const Footer = () => (
 <footer>
  <nav>
   <ul>
    <li className="header">
     SOLUTIONS
    </li>
    <li>
     <Link to="/solutions/revenue_cycle_management" >Revenue Cycle Management</Link>
    </li>
    <li>
     <Link to="/solutions/payor_contracting_and_credentialing/" >Payor Contracting & Credentialing</Link>
    </li>
    <li>
     <Link to="/solutions/management_services_and_consulting" >Management Services & Consulting</Link>
    </li>
    <li>
     <Link to="/solutions/practice_management" >Practice Management</Link>
    </li>
    <li>
     <Link to="/solutions/health_system/">Health System</Link>
    </li>
   </ul>

   <ul>
    <li className="header">
     <a>COMPANY</a>
    </li>
    <li>
     <Link to="/about" >About Us</Link>
    </li>
    <li>
     <Link to="/careers" >Career</Link>
    </li>
   </ul>

   <ul>
    <li className="header">
     <a>RESOURCES</a>
    </li>
    <li>
     <Link to="/technology" >Technology</Link>
    </li>
    <li>
     <Link to="/news_and_insights/insights/" >Insights</Link>
    </li>
   </ul>

   <ul>
    <li className="header">
     <Link to="/contact" >GET IN TOUCH</Link>
    </li>
    <li>
     <a href="mailto:info@msmhealth.com">info@msmhealth.com</a>
    </li>
    <li className="social">
     <ul>
      <li>
       <a href="https://www.facebook.com/MedicalSpecialtiesManagers/">
        <FontAwesomeIcon icon={faFacebookSquare} />
       </a>
       <a href="https://www.linkedin.com/company/medical-specialties-managers-inc./" >
        <LinkedInLogo />
       </a>
       <a href="http://twitter.com/msm_inc">
        <FontAwesomeIcon icon={faTwitter} />
       </a>
      </li>
     </ul>
    </li>
   </ul>
  </nav>

  <div className="logo-full svg">
   <Link to="/"><img src={Logo} /></Link>
  </div>
  <p>Copyright © 2019 MSM. All Rights Reserved.</p>
 </footer>
)

Footer.propTypes = {

}

Footer.defaultProps = {

}

export default Footer
