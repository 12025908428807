import { Link } from "gatsby"
import PropTypes from "prop-types"
import React, { Fragment, useEffect, useRef, useState } from "react"
import * as Scroll from 'react-scroll';
import Fade from 'react-reveal/Fade'
import "../styles/_header.scss"

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCaretDown } from '@fortawesome/free-solid-svg-icons'


import Logo from "../images/svg/msm-logo.svg"
import Lock from "../images/svg/icons/Lock.svg"
import Hamburger from "../images/svg/icons/icon-hamburger.svg"

const Element = Scroll.Element

const Header = ({ menuOpen, setMenuOpen }) => {
  const [isSticky, setSticky] = useState(false);
  const headerRef = useRef(null);


  const [isSubMenuClosed, setSubMenuClosed] = useState('closed');

  const handleSubMenuClosed = () => {
    setSubMenuClosed(isSubMenuClosed === 'closed' ? '' : 'closed');
  }

  return (
    <>
      <Fade delay={1000}>
        <header style={{ opacity: 0 }} className={`sticky-wrapper sticky '  ${!menuOpen ? ' mnu-closed ' : ''}  `} ref={headerRef}>
          <Fade delay={1400}>
            <h1 className="logo svg">
              <Link onClick={() => setMenuOpen(false)} to="/"><img src={Logo} /></Link>
            </h1>
          </Fade>
          <nav>

            <ul >
              <li>
                <Link onClick={() => setMenuOpen(false)} to="/"> Home</Link>
                <span></span>
              </li>
              <li className={`menu-plus ${isSubMenuClosed ? 'closed' : ''}`} onClick={handleSubMenuClosed}>
                <a> Solutions
            <FontAwesomeIcon icon={faCaretDown} />
                </a>
                <ul>

                  <li>
                    <Link onClick={() => setMenuOpen(false)} to="/solutions/revenue_cycle_management/" >Revenue Cycle Management </Link>
                  </li>

                  <li>
                    <Link onClick={() => setMenuOpen(false)} to="/solutions/payor_contracting_and_credentialing/">Payor Contracting & Credentialing</Link>
                  </li>
                  <li>
                    <Link onClick={() => setMenuOpen(false)} to="/solutions/management_services_and_consulting/">Management Services & Consulting</Link>
                  </li>
                  <li>
                    <Link onClick={() => setMenuOpen(false)} to="/solutions/practice_management/">Practice Management</Link>
                  </li>
                  <li>
                    <Link onClick={() => setMenuOpen(false)} to="/solutions/health_system/">Health System</Link>
                  </li>
                </ul>
                <span></span>
              </li>
              <li>
                <Link onClick={() => setMenuOpen(false)} to="/technology"> Technology</Link>
                <span></span>
              </li>

              <li className={`menu-plus ${isSubMenuClosed ? 'closed' : ''}`} onClick={handleSubMenuClosed}>
                <a> Insights
            <FontAwesomeIcon icon={faCaretDown} />
                </a>
                <ul>
                  <li>
                    <Link onClick={() => setMenuOpen(false)} to="/news_and_insights/insights/">Insights</Link>
                  </li>
                  <li>
                    <Link onClick={() => setMenuOpen(false)} to="/news_and_insights/client_success/radiology_group_value_of_true_rcm_partner/">Client Success Stories</Link>
                  </li>
                </ul>
                <span></span>
              </li>
              <li>
                <Link onClick={() => setMenuOpen(false)} to="/about"> About</Link>
                <span></span>
              </li>
              <li className="alt-nav">
                <Link onClick={() => setMenuOpen(false)} to="/careers"> Careers</Link>
              </li>
              <li className="alt-nav nav-w-icon">
                <a href="https://app.practicevision.com/login.aspx">
                  <span className="icon svg">
                    <Lock />
                  </span> Customer Login
          </a>

              </li>
            </ul>

            <button className="float-nav nav-button btn-primary">
              <Link to="/contact" onClick={() => setMenuOpen(false)}>Contact Us</Link>
            </button>

            <button id="btn-menu" className={`btn-opener svg button ${!menuOpen ? '' : 'open'}`} open={menuOpen} onClick={function () { setMenuOpen(!menuOpen) }}>

              <Hamburger />
              <span>close</span>
            </button>
          </nav>

        </header>
      </Fade>

      <Element id="stickyStart"></Element>
    </>
  )
}

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
